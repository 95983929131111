import useAgeRestriction from '@concepts/Checkout/hooks/useAgeRestriction'
import useCustomTerms from '@concepts/Checkout/hooks/useCustomTerms'
import { useCheckoutContext } from '@concepts/Checkout/store/context'
import { useFeatureFlag } from '@concepts/FeatureFlag/store/context'
import {
  CART_EXPRESS_CHECKOUT,
  PUBLISHER
} from '@concepts/FeatureFlag/utils/constants'
import { Purchase } from '@concepts/Purchase/types/purchase'
import { pluralize } from '@utils/strings'
import { debounce } from 'throttle-debounce'
import { CartContextType, useCartContext } from '../store/context'
import { useCartPopupContext } from '../store/popupContext'
import { Cart } from '../types/cart'

const getItemsText = (cartProductsQuantity: number) => {
  return `${cartProductsQuantity} ${pluralize(
    cartProductsQuantity,
    'item',
    'items'
  )}`
}

type UseMiniCart = {
  quantity: number
  navbarHeight: string
  popUpMobileMaxHeight: string
  cart: Cart
  setCart: Function
  addSaleToCart: CartContextType['addSaleToCart']
  isSubmitting: Purchase['isSubmitting']
  hidePaypal: boolean
  itemsText: string
  hasQuantity: boolean
  isDisabled: boolean
  handleUpdateQuantity: Function
  removeSaleFromCart: Function
}

const useMiniCart = (): UseMiniCart => {
  const { navbarHeight, popUpMobileMaxHeight } = useCartPopupContext()
  const {
    cart,
    setCartItem,
    dispatch,
    removeSaleFromCart,
    cartProductsQuantity,
    setCart,
    addSaleToCart
  } = useCartContext()
  const [{ purchase }] = useCheckoutContext()
  const FeatureFlag = useFeatureFlag()
  const { disabledByTerms } = useCustomTerms()
  const { disabledByAge } = useAgeRestriction()

  const hideExpressCheckout = FeatureFlag.isDisabled(
    CART_EXPRESS_CHECKOUT,
    PUBLISHER
  )

  const handleUpdateQuantity = (cartId: number, quantity: string): void => {
    const value = parseInt(quantity)

    debounce(200, setCartItem({ id: cartId, quantity: value }))
  }

  const dispatchCart = (cart: Cart) => {
    dispatch(setCart(cart))
  }

  return {
    quantity: cartProductsQuantity,
    navbarHeight,
    popUpMobileMaxHeight,
    cart,
    setCart: dispatchCart,
    addSaleToCart,
    isSubmitting: purchase.isSubmitting,
    hidePaypal:
      cart.hasCannabidiolSale || cart.hasRecurringSale || hideExpressCheckout,
    itemsText: getItemsText(cartProductsQuantity),
    hasQuantity: Boolean(cartProductsQuantity),
    isDisabled: disabledByTerms || disabledByAge,
    handleUpdateQuantity,
    removeSaleFromCart
  }
}

export default useMiniCart
