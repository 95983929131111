import { useState } from 'react'

import CoinIcon from '@icons/coin.svg'
import PreferencesIcon from '@icons/preferences.svg'
import RefreshIcon from '@icons/refresh.svg'
import ShoppingCartIcon from '@icons/shopping-cart.svg'
import SupportIcon from '@icons/support.svg'
import { AccountIcon, StarIcon, WishlistIcon } from '@atoms/Icon/Icon'

import useGuestToken from '@concepts/Auth/hooks/useGuestToken'
import { useAuthContext } from '@concepts/Auth/store/context'

import { getQueryString } from '@utils/url'
import { PURCHASES_URL, REVIEWS_URL } from '@config/routes'
import { User } from '@concepts/Auth/types/User'
import { supportLinks } from '@utils/supportLinks'

export type AccountItem = {
  to: string
  label: string
  target?: string
  icon: string
  isHidden?: boolean
}

export type MenuItems = {
  accountMenu: Array<AccountItem>
  menuLinks: {
    purchases: string
    account: string
    saved: string
    preferences: string
    credits: string
    support: string
    subscriptions: string
    reviews: string
  }
  showAccountMenu: boolean
  isGuestOrLoggedUser: boolean
  toggleAccountMenu: Function
  user: User
  loadingUser: boolean
}

const useMenuItems = (): MenuItems => {
  const [showMenu, toggleAccountMenu] = useState(false)
  const [{ isSignedIn, user, loading }] = useAuthContext()
  const { gtoken } = useGuestToken()

  const isGuestOrLoggedUser = isSignedIn || Boolean(gtoken)

  const showAccountMenu = isGuestOrLoggedUser && showMenu

  const menuLinks = {
    account: `/user${getQueryString({ show: 'account-tab', gtoken })}`,
    saved: `/user${getQueryString({ show: 'wishlist-tab', gtoken })}`,
    preferences: `/user${getQueryString({ show: 'preferences-tab', gtoken })}`,
    credits: `/user/credits${getQueryString({ gtoken })}`,
    purchases: `${PURCHASES_URL}${getQueryString({ gtoken })}`,
    subscriptions: `/user/subscriptions${getQueryString({ gtoken })}`,
    support: supportLinks.contact(),
    reviews: `${REVIEWS_URL}${getQueryString({ gtoken })}`
  }

  const accountMenu = [
    {
      label: 'Account',
      icon: AccountIcon,
      to: menuLinks.account
    },
    {
      label: 'Credits',
      icon: CoinIcon,
      to: menuLinks.credits
    },
    {
      label: 'Purchases',
      icon: ShoppingCartIcon,
      to: menuLinks.purchases
    },
    {
      label: 'Preferences',
      icon: PreferencesIcon,
      to: menuLinks.preferences
    },
    {
      label: 'Support',
      icon: SupportIcon,
      to: menuLinks.support,
      target: '_blank'
    },
    {
      label: 'Wishlist',
      icon: WishlistIcon,
      to: menuLinks.saved
    },
    {
      label: 'Subscriptions',
      icon: RefreshIcon,
      to: menuLinks.subscriptions,
      isHidden: !user?.hasSubscriptions
    },
    {
      label: 'My Reviews',
      icon: StarIcon,
      to: menuLinks.reviews
    }
  ].filter((item) => !item.isHidden)

  return {
    accountMenu,
    menuLinks,
    isGuestOrLoggedUser,
    showAccountMenu,
    toggleAccountMenu,
    loadingUser: loading,
    user
  }
}

export default useMenuItems
