import { createContext, Dispatch, useContext } from 'react'
import { Cart, CartState } from '../types/cart'
import { Coupons } from '../types/coupon'
import { F } from 'ramda'
import { Action } from '../types/actions'
import { Credit } from '../types/credit'
import { AddSaleToCartProps } from '../hooks/useCartOperations'

export type CartContextType = {
  state: CartState
  cart: Cart
  dispatch: Dispatch<Action>
  isLoading: boolean
  coupons: Coupons
  credit: Credit
  setCart: Function
  setCartItem: Function
  setCartShippingAddress: Function
  addSaleToCart: (params: AddSaleToCartProps) => void
  updateCart: Function
  removeSaleFromCart: Function
  syncCartForCheckout: Function
  cartProductsQuantity: number
}

export const CartContext = createContext<CartContextType>({
  state: {} as CartState,
  dispatch: F,
  cart: {} as Cart,
  isLoading: true,
  coupons: {
    codes: []
  } as Coupons,
  credit: {
    isApplied: false,
    payEntirelyWithCredits: false
  },
  setCart: F,
  setCartItem: F,
  setCartShippingAddress: F,
  addSaleToCart: F,
  updateCart: F,
  removeSaleFromCart: F,
  syncCartForCheckout: F,
  cartProductsQuantity: 0
})

export const useCartContext = (): CartContextType => useContext(CartContext)
