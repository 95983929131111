import Link from '@atoms/UILink/Link'
import { Text } from '@lib/UIComponents'

type SuccessMessageComponentProps = {
  alreadySubscribed: boolean
}

const SuccessMessageComponent = ({
  alreadySubscribed
}: SuccessMessageComponentProps) => {
  if (alreadySubscribed) {
    return (
      <Text>
        This email address is already associated with an account.{' '}
        <Link to="/sign_in" sx={{ textDecoration: 'underline' }}>
          Please log in here.
        </Link>
      </Text>
    )
  }

  return (
    <Text>
      Thanks! You're subscribed to get notifications of our latest deals.
    </Text>
  )
}

export { SuccessMessageComponent }
