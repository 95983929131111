/* istanbul ignore file */
import { createContext, useContext, Dispatch } from 'react'
import { Checkout } from '../types/Checkout'
import { UserAccountState } from '@concepts/Address/store/reducer'
import { CreditCardsState } from '@concepts/CreditCard/store/reducer'
import { Purchase as PurchaseState } from '@concepts/Purchase/types/purchase'

import noop from '@utils/noop'
import { F } from 'ramda'

export type CheckoutContextType = [
  {
    checkout: Checkout
    addresses: UserAccountState
    creditCards: CreditCardsState
    purchase: PurchaseState
    acceptCustomTerms: boolean
    acceptAgeRestriction: boolean
    acceptSaleConditionsTerms: boolean
  },
  Dispatch<unknown>,
  {
    setAcceptCustomTerms: Function
    setAcceptAgeRestriction: Function
    setAcceptSaleConditionsTerms: Function
    completeCheckout: Function
  }
]

export const CheckoutContext = createContext<CheckoutContextType>([
  {
    checkout: {} as Checkout,
    addresses: {} as UserAccountState,
    creditCards: {} as CreditCardsState,
    purchase: {} as PurchaseState,
    acceptCustomTerms: false,
    acceptAgeRestriction: false,
    acceptSaleConditionsTerms: false
  },
  noop as Dispatch<unknown>,
  {
    setAcceptCustomTerms: F,
    setAcceptAgeRestriction: F,
    setAcceptSaleConditionsTerms: F,
    completeCheckout: F
  }
])

export const useCheckoutContext = (): CheckoutContextType =>
  useContext(CheckoutContext)
