import Router from 'next/router'
import routes from '@config/routes'
import Redirect from '@utils/redirect'
import { isClient } from '@utils/env'
import { getCookie, setCookie, removeCookie } from '@utils/cookies'
import { isPresent } from '@utils/logic'

export const setReturnToCookie = (): void => {
  if (isClient()) setCookie('return_to', Router.asPath)
}

export const redirectToLoginPage = (): void => {
  setReturnToCookie()

  Redirect({
    pathname: routes.login()
  })
}

export const redirectToSignUpPage = (): void => {
  setReturnToCookie()

  Redirect({
    pathname: routes.signUp()
  })
}

export const redirectToForgotYourPasswordPage = ({
  shouldSetReturnTo = true
} = {}): void => {
  if (shouldSetReturnTo) {
    setReturnToCookie()
  }

  Redirect({
    pathname: routes.forgotYourPassword()
  })
}

export const redirectAfterAuthentication = (
  redirect?: Parameters<typeof Redirect>[0]
): void => {
  const returnTo = getCookie('return_to') || getCookie('referrer_to')

  if (!isPresent(redirect) && returnTo) {
    removeCookie('return_to')
    removeCookie('referrer_to')

    return Redirect({
      pathname: returnTo
    })
  }

  Redirect(redirect || { pathname: '/' })
}
