import { usePublisherContext } from '../../Publisher/store/context'
import { useCheckoutContext } from '../store/context'

export type useCustomTermsType = {
  customTerms: string
  isEnabled: boolean
  disabledByTerms: boolean
  acceptCustomTerms: boolean
  setAcceptCustomTerms: Function
}

const useCustomTerms = (): useCustomTermsType => {
  const [{ acceptCustomTerms }, , { setAcceptCustomTerms }] =
    useCheckoutContext()
  const { useCustomTerms, customTerms } = usePublisherContext()
  const disabledByTerms = useCustomTerms ? !acceptCustomTerms : false

  return {
    customTerms: String(customTerms),
    isEnabled: Boolean(useCustomTerms),
    disabledByTerms,
    acceptCustomTerms,
    setAcceptCustomTerms
  }
}

export default useCustomTerms
