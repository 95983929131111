import { MouseEventHandler, useEffect } from 'react'

import { Box } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

export type OverlayProps = {
  freezeScroll?: boolean
  onClick?: MouseEventHandler<HTMLDivElement>
  sx?: UIStyles
}

const useLockBodyScroll = (freezeScroll: boolean): void => {
  useEffect(() => {
    if (!freezeScroll) return

    const originalStyle = window.getComputedStyle(document.body).overflow
    document.body.style.overflow = 'hidden'

    return (): void => {
      document.body.style.overflow = originalStyle
    }
  }, [freezeScroll])
}

const Overlay = ({ freezeScroll = false, sx = {}, ...props }: OverlayProps) => {
  useLockBodyScroll(freezeScroll)

  return (
    <Box
      sx={{
        bg: 'overlay',
        zIndex: 'overlay',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        ...sx
      }}
      {...props}
    />
  )
}

export default Overlay
