import Overlay from '@atoms/Overlay/Overlay'
import { useModal } from './hooks/useModal'
import { UIStyles } from '@lib/UIComponents/types'
import { Box } from '@lib/UIComponents'

export type ModalProps = {
  showModal: boolean
  toggleModal: (visibility: boolean) => void
  sx?: UIStyles
  children: React.ReactNode
}

const getTransformPosition = (showModal: boolean): object => ({
  transform: showModal ? `translateY(0)` : `translateY(-200%)`
})

const Modal = ({
  sx,
  children,
  showModal,
  toggleModal,
  ...props
}: ModalProps) => {
  const { closeModal } = useModal({ toggleModal })

  return (
    <Box
      sx={{
        zIndex: 'modal',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        ...getTransformPosition(showModal)
      }}
    >
      <Overlay data-testid="modal-overlay" onClick={closeModal} />
      <Box
        sx={{
          borderRadius: '10px',
          backgroundColor: 'background',
          m: [2, '3em auto'],
          position: 'relative',
          zIndex: 'modal',
          padding: [30, 50],
          minWidth: 320,
          maxWidth: ['100%', 600],
          maxHeight: ['100%', '90%'],
          overflow: 'auto',
          boxShadow: '0px 4px 50px rgba(0, 0, 0, 0.15)',
          transition: 'all 350ms',
          ...getTransformPosition(showModal),
          ...sx
        }}
        {...props}
      >
        {children}
      </Box>
    </Box>
  )
}

export default Modal
