import Button from '@atoms/UIButton/Button'
import { ChevronLeftIcon } from '@atoms/Icon/Icon'
import { ButtonProps } from '@lib/UIComponents/types'
import { Flex } from '@lib/UIComponents'

type BackMenuProps = {
  children: React.ReactNode
} & ButtonProps

const BackMenu = ({ children, onClick }: BackMenuProps) => (
  <Button
    size="small"
    variant="capsLink"
    sx={{
      fontSize: [3, 4],
      p: 0,
      mb: 5,
      mt: [2, 5],
      lineHeight: 1,
      display: 'flex',
      color: 'menuSecondaryColor',
      '&:hover': {
        color: 'primary'
      },
      justifyContent: 'left',
      width: '100%'
    }}
    onClick={onClick}
  >
    <Flex
      sx={{
        position: 'relative',
        left: '-7px',
        top: '1px',
        fontSize: '.85em'
      }}
    >
      <ChevronLeftIcon />
    </Flex>
    {children}
  </Button>
)

export default BackMenu
