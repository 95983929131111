import { forwardRef } from 'react'
import {
  Button as RebassButton,
  ButtonProps as RebassButtonProps
} from 'rebass'
import Loader from '@atoms/Loader/Loader'
import { Buttons } from '@theme/buttons'

export type Size = 'small' | 'medium' | 'large'

export type ButtonTypes = keyof Buttons

export type Props = {
  block?: boolean
  loading?: boolean
  size?: Size
  variant?: ButtonTypes
  to?: string
} & RebassButtonProps & { css?: string }

const sizes = (size: Size, variant: string): object => {
  const setHeight = variant !== 'close' && variant !== 'icon'

  return {
    small: {
      minHeight: setHeight ? 30 : 'auto',
      px: 3,
      fontSize: 1
    },
    medium: {
      minHeight: setHeight ? 40 : 'auto',
      px: 4,
      fontSize: 2
    },
    large: {
      minHeight: setHeight ? 45 : 'auto',
      px: 8,
      pb: '0.75rem',
      fontSize: 2
    }
  }[size]
}

const Button = forwardRef(
  (
    {
      block = false,
      loading = false,
      disabled = false,
      size = 'medium',
      variant = 'primary',
      sx,
      ...props
    }: Props,
    ref
  ) => (
    <RebassButton
      ref={ref}
      variant={variant}
      disabled={disabled || loading}
      sx={{
        ...sizes(size, variant),
        ...(block && {
          display: 'block',
          width: '100%'
        }),
        ...sx
      }}
      {...props}
    >
      {loading ? <Loader /> : props.children}
    </RebassButton>
  )
)

export default Button
