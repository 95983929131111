import Container from '@atoms/UIContainer/Container'
import { useNavBarContext } from '@concepts/Publisher/store/context'
import { InlineMenuItem } from './InlineMenuItem'
import { Box } from '@lib/UIComponents'

export const InlineMenu = () => {
  const { rootNav } = useNavBarContext()

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        width: 'fit-content',
        overflowX: 'auto',
        '::-webkit-scrollbar': {
          display: ['none', 'none', 'auto']
        },
        scrollbarWidth: ['none', 'none', 'auto']
      }}
      as="ul"
      data-testid="inline-menu"
    >
      {rootNav &&
        rootNav.children &&
        rootNav.children.map((navItem) => {
          return (
            <Box
              sx={{
                minWidth: ['auto', 'auto', 'auto', 0, 0],
                color: 'navBarText'
              }}
              as="li"
              key={navItem.id}
            >
              <InlineMenuItem navItem={navItem} key={navItem.id} />
            </Box>
          )
        })}
    </Container>
  )
}
