import { keyframes } from '@emotion/react'
import { Box } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'
import { lighten, darken } from 'polished'

export type LoaderProps = {
  dotColor?: string
  lightenColor?: boolean
  sx?: UIStyles
}

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

const ellipsis2 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const ellipsis3 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(18px, 0);
  }
`

const styles = {
  loader: {
    display: 'inline-block',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: '60px',
    height: '15px'
  },
  dot: (color: string): object => ({
    position: 'absolute',
    top: 0,
    width: '10px',
    height: '10px',
    borderRadius: 'circle',
    backgroundColor: color,
    animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)'
  }),
  first: {
    left: '6px',
    animation: `${ellipsis1} 600ms infinite`
  },
  second: {
    left: '6px',
    animation: `${ellipsis3} 600ms infinite`
  },
  third: {
    left: '24px',
    animation: `${ellipsis3} 600ms infinite`
  },
  fourth: {
    left: '43px',
    animation: `${ellipsis2} 600ms infinite`
  }
}

const Loader = ({
  dotColor = 'white',
  lightenColor = false,
  ...props
}: LoaderProps) => {
  const changeColor = (percentage: number, color: string): string =>
    lightenColor ? lighten(percentage, color) : darken(percentage, color)

  return (
    <Box sx={styles.loader} {...props}>
      <Box sx={{ ...styles.dot(dotColor), ...styles.first }} />
      <Box
        sx={{ ...styles.dot(changeColor(0.2, dotColor)), ...styles.second }}
      />
      <Box
        sx={{ ...styles.dot(changeColor(0.4, dotColor)), ...styles.third }}
      />
      <Box
        sx={{ ...styles.dot(changeColor(0.6, dotColor)), ...styles.fourth }}
      />
    </Box>
  )
}

export default Loader
