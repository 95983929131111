import { useState } from 'react'

import Container from '@atoms/UIContainer/Container'
import Button from '@atoms/UIButton/Button'
import Link from '@atoms/UILink/Link'
import { ChevronDownThinIcon, MenuIcon } from '@atoms/Icon/Icon'
import Logo from '@molecules/Logo/Logo'
import Menu from '@molecules/Menu/Menu'

import SearchBox from './SearchBox'
import AccountMenu from '@molecules/AccountMenu/AccountMenu'
import useMenuItems from '@molecules/AccountMenu/hooks/useMenuItems'
import { useCartPopupContext } from '@concepts/Cart/store/popupContext'
import { mergeDeepRight, not } from 'ramda'
import { InlineMenu } from '@molecules/Menu/InlineMenu'
import useMediaQuery from '@hooks/useMediaQuery'
import { defaultMediaSizes } from '@theme/breakpoints'
import { Box, Flex, Text } from '@lib/UIComponents'
import CartButton from './CartButton'

const styles = {
  wrapper: {
    borderBottom: '1px solid',
    borderColor: 'navBarBorder',
    bg: 'headerBackground'
  },
  container: {
    height: [77, 77, 77, 100],
    px: [3, 3],
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  content: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  wrapperAccountInfo: (isLoading: boolean) => ({
    opacity: isLoading ? 0 : 1
  }),
  inlineMenuContainer: {
    display: 'flex',
    height: ['56px', '56px', '56px', 'auto', 'auto'],
    alignItems: 'center',
    margin: '0 auto'
  },
  accountInfo: {
    color: 'navBarText',
    border: 0,
    pl: 3,
    justifyContent: 'flex-end',
    height: '100%',
    alignItems: 'center',
    position: 'relative',
    py: '36px'
  },
  welcomeText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: [3, 3],
    fontWeight: 'heading',
    textTransform: 'capitalize',
    svg: {
      position: 'relative',
      top: '3px',
      fontSize: 2,
      ml: 3
    }
  },
  signInBtn: {
    borderColor: 'navBarText',
    color: 'navBarText',
    lineHeight: '24px',
    minHeight: 'auto',
    py: '11px',
    px: 8,
    '&:hover': {
      color: 'singInHoverText',
      borderColor: 'signInHoverOutline',
      backgroundColor: 'signInHoverColor'
    }
  },
  searchBox: {
    mr: [0, 0, 0, 8]
  },
  logo: {
    position: ['absolute', 'absolute', 'absolute', 'relative'],
    left: ['50%', '50%', '50%', 'auto'],
    transform: [
      'translateX(-50%)',
      'translateX(-50%)',
      'translateX(-50%)',
      'none'
    ]
  }
}

type Props = {
  useSearchBox?: boolean
  useInlineMenu?: boolean
  useMiniCartButton?: boolean
  sx?: object
}

const NavBar = ({
  useSearchBox,
  useInlineMenu,
  useMiniCartButton,
  sx = {}
}: Props) => {
  const {
    showAccountMenu,
    isGuestOrLoggedUser,
    toggleAccountMenu,
    loadingUser,
    user
  } = useMenuItems()
  const { isOpen: isOpenCartPopup } = useCartPopupContext()

  const [isOpen, setIsOpen] = useState(false)
  const openMenu = () => setIsOpen(true)
  const closeMenu = () => setIsOpen(false)

  const isMobile = useMediaQuery(defaultMediaSizes.ScreenM)

  const firstName = user?.firstName?.split(' ')[0]

  return (
    <Box data-testid="navbar" sx={mergeDeepRight(styles.wrapper, sx)}>
      <Container sx={styles.container}>
        {isMobile && (
          <Button
            onClick={openMenu}
            size="small"
            aria-label="Open Menu"
            sx={{
              border: 0,
              fontSize: '30px',
              justifyContent: 'flex-start',
              padding: 0,
              mr: 4,
              color: 'navBarText',
              bg: 'transparent'
            }}
          >
            <MenuIcon />
          </Button>
        )}

        <Logo sx={styles.logo} />

        <Flex sx={styles.content}>
          {useSearchBox && <SearchBox sx={styles.searchBox} />}

          {not(isMobile) && (
            <Box sx={styles.wrapperAccountInfo(loadingUser)}>
              {isGuestOrLoggedUser ? (
                <Flex
                  data-testid="account-info-box"
                  sx={styles.accountInfo}
                  onMouseLeave={() => {
                    toggleAccountMenu(false)
                  }}
                >
                  <Text
                    sx={styles.welcomeText}
                    onMouseEnter={() => {
                      /* istanbul ignore else */
                      if (!isOpenCartPopup) {
                        toggleAccountMenu(true)
                      }
                    }}
                  >
                    Hi{firstName ? `, ${firstName}` : ''}{' '}
                    <ChevronDownThinIcon />
                  </Text>

                  <AccountMenu
                    sx={{ display: showAccountMenu ? 'flex' : 'none' }}
                  />
                </Flex>
              ) : (
                <Button
                  to="/sign_in"
                  as={Link}
                  sx={styles.signInBtn}
                  variant="outlineDark"
                >
                  Sign In
                </Button>
              )}
            </Box>
          )}

          {useMiniCartButton && <CartButton />}
        </Flex>
      </Container>
      <Menu hidden={!isOpen} closeHandler={closeMenu} />
      <Box sx={{ bg: 'navBarBg' }}>
        {useInlineMenu && (
          <Container
            sx={styles.inlineMenuContainer}
            as="nav"
            role="navigation"
            className="inline-navigation"
          >
            <InlineMenu />
          </Container>
        )}
      </Box>
    </Box>
  )
}

export default NavBar
