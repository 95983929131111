import { memo } from 'react'
import { nth, last } from 'ramda'

import BackMenu from '@molecules/Menu/BackMenu'
import MenuItemTitle from '@molecules/Menu/MenuItemTitle'
import SiteLinks from '@molecules/SiteLinks/SiteLinks'
import { trackingNavigationAnalytics } from '@lib/gtm/events/navigation'
import { Menu } from './menuFormatter'
import { Box } from '@lib/UIComponents'

export type MenuListProps = {
  expanded: string
  expand: Function
  setMenuLevel: Function
  menuLevel: number
  items: Record<string, Array<Menu>>
  rootId: string
  previousMenu: Array<string>
  setPreviousMenu: Function
}

const styles = {
  menu: (open: boolean, previousOpen: boolean) => ({
    transform: open
      ? 'translateX(0%)'
      : previousOpen
        ? 'translateX(-100%)'
        : 'translateX(100%)',
    position: 'absolute',
    margin: 0,
    inset: 0,
    overflowX: 'scroll',
    willChange: 'transform',
    visibility: open ? 'visible' : 'hidden',
    transition: 'all 350ms',
    px: ['22px', 8]
  }),
  siteLinks: {
    lineHeight: 2.5,
    a: {
      '&:hover': { color: 'primary' },
      '&:active, &:focus': { color: 'primaries.7' }
    }
  }
}

const MenuList = ({
  expand,
  menuLevel,
  setMenuLevel,
  expanded,
  items,
  previousMenu,
  rootId,
  setPreviousMenu
}: MenuListProps) => {
  const lastPreviousMenu = last(previousMenu)
  const trackingAndExpand = (
    hasSubNav: boolean,
    itemId: string,
    name: string
  ): void => {
    /* istanbul ignore else */
    if (trackingNavigationAnalytics(name)[menuLevel])
      trackingNavigationAnalytics(name)[menuLevel]()

    /* istanbul ignore else */
    if (hasSubNav) {
      expand(itemId)
      setMenuLevel(menuLevel + 1)
    }
  }

  return (
    <>
      {Object.keys(items).map((key) => {
        const menuItems = items[key] as Array<Menu>
        const firstItem = nth(0, menuItems) as Menu

        const root = firstItem.parentId === rootId
        const currentlyOpen = expanded === key
        const previousOpen = previousMenu.includes(key)

        return (
          <Box
            as="ul"
            key={key}
            sx={styles.menu(currentlyOpen, previousOpen)}
            aria-hidden={!currentlyOpen}
          >
            {!root && (
              <Box as="li">
                <BackMenu
                  onClick={(): void => {
                    const previousData = previousMenu.filter(
                      (prev) => prev !== lastPreviousMenu
                    )
                    expand(lastPreviousMenu)
                    setMenuLevel(menuLevel - 1)
                    setPreviousMenu(previousData)
                  }}
                >
                  {firstItem.parentName}
                </BackMenu>
              </Box>
            )}
            {menuItems.map((item) => {
              return (
                <Box as="li" key={item.id}>
                  <MenuItemTitle
                    onClick={() => {
                      setPreviousMenu([...previousMenu, expanded])
                      trackingAndExpand(
                        item.hasSubMenu,
                        item.id,
                        item.name as string
                      )
                    }}
                    size={root ? 'large' : 'medium'}
                    title={item.name as string}
                    url={item.url as string}
                    arrow={item.hasSubMenu}
                  />
                </Box>
              )
            })}
            {root && (
              <SiteLinks color="menuSecondaryColor" sx={styles.siteLinks} />
            )}
          </Box>
        )
      })}
    </>
  )
}

export default memo(MenuList)
