import { useState, useRef } from 'react'
import { SearchIcon } from '@atoms/Icon/Icon'
import Button from '@atoms/Button/Button'
import { Box, SxStyleProp } from 'rebass'
import { Input as RebassInput, InputProps } from '@rebass/forms'
import { mergeDeepRight, not } from 'ramda'
import { defaultMediaSizes } from '@theme/breakpoints'
import useMediaQuery from '@hooks/useMediaQuery'

const TypedRebassInput = RebassInput as React.FunctionComponent<InputProps>

const styles = {
  form: (isOpen: boolean) => ({
    position: 'relative',
    transition: '300ms ease',
    width: '100%',
    maxWidth: [
      isOpen ? '1024px' : '50px',
      isOpen ? '1024px' : '50px',
      isOpen ? '1024px' : '50px',
      '365px'
    ]
  }),
  searchIcon: {
    border: 0,
    fontSize: ['24px', 5],
    padding: 0,
    position: 'absolute',
    top: '50%',
    left: 3,
    background: 'transparent',
    transform: 'translateY(-50%)',
    '&:disabled': {
      opacity: 1
    },
    '&:hover': {
      color: 'blacks.0'
    }
  },
  input: (isOpen: boolean): object => ({
    transition: '300ms ease 100ms',
    height: '48px',
    borderWidth: [isOpen ? 1 : 0, isOpen ? 1 : 0, isOpen ? 1 : 0, 1],
    borderRadius: 'circle',
    color: 'headerSearchBarColor',
    background: [
      isOpen ? 'white' : 'transparent',
      isOpen ? 'white' : 'transparent',
      isOpen ? 'white' : 'transparent',
      'white'
    ],
    pl: [isOpen ? 9 : 0, isOpen ? 9 : 0, isOpen ? 9 : 0, 9],
    pr: [isOpen ? 0 : 9, 0, 0, 0],
    opacity: [isOpen ? 1 : 0, isOpen ? 1 : 0, isOpen ? 1 : 0, 1],
    '&:focus-visible': {
      outline: 0
    }
  })
}

const SearchBox = ({ sx = {} }: { sx?: SxStyleProp }) => {
  const [isOpen, setIsOpen] = useState(false)

  const isMobile = useMediaQuery(defaultMediaSizes.ScreenM)
  const searchRef = useRef<HTMLInputElement>()

  const openAndFocus = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault()
    setIsOpen((state) => !state)

    setTimeout(() => {
      /* istanbul ignore next */
      searchRef.current?.focus()
    }, 0)
  }

  return (
    <Box
      as="form"
      action="/search"
      method="get"
      acceptCharset="UTF-8"
      sx={mergeDeepRight(styles.form(isOpen), sx as object)}
    >
      <input name="utf8" type="hidden" value="✓" />
      <TypedRebassInput
        id="query"
        data-testid="search-box"
        sx={styles.input(isOpen)}
        placeholder="Search..."
        aria-label="Search Site"
        name="query"
        required
        onBlur={(): void => {
          setIsOpen(false)
        }}
        ref={searchRef}
      />

      {isMobile && (
        <Button
          variant="icon"
          color="headerSearchBarColor"
          aria-label="Search"
          onClick={openAndFocus}
          type="button"
          disabled={isOpen}
          sx={styles.searchIcon}
        >
          <SearchIcon />
        </Button>
      )}

      {not(isMobile) && (
        <Button
          variant="icon"
          color="headerSearchBarColor"
          aria-label="Search"
          sx={styles.searchIcon}
        >
          <SearchIcon />
        </Button>
      )}
    </Box>
  )
}

export default SearchBox
