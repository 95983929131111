import { useState } from 'react'

import Close from '@atoms/Close/Close'
import { UIStyles } from '@lib/UIComponents/types'
import { Flex } from '@lib/UIComponents'
import Container from '@atoms/UIContainer/Container'

const styles = {
  flex: {
    overflow: 'hidden',
    transition: 'max-height 250ms',
    position: 'relative'
  },
  container: {
    height: ['auto', 'auto', 'auto', '48px'],
    w: '100%',
    py: [2, '0.75rem'],
    px: [4, 3, 3, 1],
    pr: ['50px', '50px', '50px', 0],
    textAlign: 'center',
    position: 'relative'
  }
}

type AlertProps = {
  dismissible?: boolean
  display?: string | Array<string>
  sx?: UIStyles
  color?: string
  children: React.ReactNode
}

const Alert = ({
  children,
  dismissible = true,
  display,
  sx,
  color
}: AlertProps) => {
  const [dismissed, setDismissed] = useState(false)

  const dismiss = () => {
    setDismissed(true)
  }

  return (
    <Flex
      role="alert"
      sx={{ ...styles.flex, maxHeight: dismissed ? 0 : 200, ...sx }}
    >
      <Container sx={{ ...styles.container, display }}>
        {children}
        {dismissible && (
          <Close
            onClick={dismiss}
            sx={{
              position: 'absolute',
              top: ['0px', '5px', '5px', '50%'],
              right: ['12px', '18px'],
              transform: ['none', 'none', 'none', 'translateY(-50%)'],
              color: color || ''
            }}
            data-dismiss="alert"
          />
        )}
      </Container>
    </Flex>
  )
}

export default Alert
