import Container from '@atoms/UIContainer/Container'
import { Maybe, NavItem } from 'src/generated/graphql'
import { normalizeMenuUrl } from '@utils/url'

import Link from '@atoms/UILink/Link'
import navigationAnalytics from '@lib/gtm/events/navigation'
import { colors } from '@lib/UIComponents'

const styles = {
  flexDirection: 'column',
  backgroundColor: 'headerBackground',
  position: 'absolute',
  maxWidth: '180px',
  padding: '10px 30px',
  display: 'none',
  visibility: 'hidden',
  boxShadow: `0 4px 15px 0 ${colors.blacks[850]}`,
  mt: '-10px',
  zIndex: 10
}

type MenuDropdownProps = {
  navigationChildren?: Maybe<NavItem[]>
}

const MenuDropdown = ({ navigationChildren }: MenuDropdownProps) => {
  return (
    <>
      {navigationChildren && navigationChildren.length > 0 && (
        <Container sx={styles} as="ul" aria-label="sub menu">
          {navigationChildren.map((navItem) => {
            return (
              <Container key={navItem.id} sx={{ marginY: '5px' }} as="li">
                <Link
                  sx={{
                    ':hover': { textDecoration: 'underline' }
                  }}
                  to={normalizeMenuUrl(navItem.url || '/')}
                  onClick={() => {
                    navigationAnalytics.trackInlineMenu(
                      window.location.href,
                      navItem.url
                    )
                  }}
                >
                  {navItem.name}
                </Link>
              </Container>
            )
          })}
        </Container>
      )}
    </>
  )
}

export { MenuDropdown }
