import { NavItem } from 'src/generated/graphql'
import { groupBy, dissoc } from 'ramda'
import { Maybe } from 'src/types/maybe'

export type Menu = NavItem & {
  parentId: string
  parentName: string
  hasSubMenu: boolean
}

const toMenu = (
  nav: NavItem,
  parentId: string,
  parentName: string
): Array<Menu> => [
  {
    ...dissoc('children', nav),
    parentId,
    parentName,
    hasSubMenu: Boolean(nav.children?.length)
  },
  ...toAllMenus(nav)
]

export const toAllMenus = (nav: NavItem): Array<Menu> =>
  nav.children?.flatMap((c) => toMenu(c, nav.id, nav.name as string)) || []

const byParent = (item: Menu): string => item.parentId

export const adaptMenuChildrenToMenu = (
  rootNav: Maybe<NavItem>
): Record<string, Array<Menu>> =>
  rootNav
    ? (groupBy(byParent, toAllMenus(rootNav)) as Record<string, Array<Menu>>)
    : ([] as unknown as Record<string, Array<Menu>>)
