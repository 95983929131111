import { usePublisherContext } from '@concepts/Publisher/store/context'
import { pathOr } from 'ramda'
import { useState } from 'react'
import { EmailBanner as GQEmailBanner } from 'src/generated/graphql'

type UseEmailBanner = {
  isBannerOpen: boolean
  showModal: boolean
  closeBanner: () => void
  toggleModal: (isOpen: boolean) => void
}

const useEmailBanner = (): UseEmailBanner => {
  const [visible, setVisible] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const { active } = pathOr<GQEmailBanner>(
    {
      offerText: '',
      saleSlug: '',
      active: false,
      modalBody: '',
      modalTitle: '',
      modalButtonText: ''
    },
    ['layout', 'emailBanner'],
    usePublisherContext()
  )

  const isBannerOpen = Boolean(active) && visible

  const closeBanner = () => {
    setVisible(false)
  }

  const toggleModal = (isOpen: boolean) => {
    setShowModal(isOpen)
  }

  return { isBannerOpen, showModal, closeBanner, toggleModal }
}

export { useEmailBanner }
