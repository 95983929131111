import analytics from '@lib/gtm/analytics'
import { Maybe } from 'src/types/maybe'

type HelloBarAnalytics = {
  trackLink: (
    currentUrl: string,
    destinationUrl: string,
    helloBarText: Maybe<string>
  ) => void
}

const helloBarAnalytics: HelloBarAnalytics = {
  trackLink: (currentUrl, destinationUrl, helloBarText = '') => {
    analytics.track('HelloBar', 'click', {
      eventLabel: 'link',
      currentUrl,
      destinationUrl,
      helloBarText
    })
  }
}

export default helloBarAnalytics
