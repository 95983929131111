import { useState, useEffect, useCallback } from 'react'
import { defaultMediaSizes } from '@theme/breakpoints'
import { isClient } from '@utils/env'

const useMediaQuery = (
  maxWidth: number = defaultMediaSizes.ScreenS
): boolean => {
  const query = `screen and (max-width: ${maxWidth}px)`

  const doesMediaMatches = useCallback((): boolean => {
    if (!isClient()) {
      return false
    }

    return window.matchMedia(query).matches
  }, [query])

  const [matches, setMatches] = useState(false)

  const handleSetMatches = useCallback(() => {
    setMatches(doesMediaMatches())
  }, [doesMediaMatches])

  useEffect(() => {
    handleSetMatches()

    window.addEventListener('resize', handleSetMatches)

    return () => {
      window.removeEventListener('resize', handleSetMatches)
    }
  }, [handleSetMatches])

  return matches
}

export default useMediaQuery
