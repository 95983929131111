import { LogoutIcon } from '@atoms/Icon/Icon'
import Button from '@atoms/UIButton/Button'
import Link from '@atoms/UILink/Link'
import { useAuthContext } from '@concepts/Auth/store/context'
import { Box, colors } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'
import useMenuItems, {
  AccountItem
} from '@molecules/AccountMenu/hooks/useMenuItems'

import { fadeIn } from 'src/components/theme/animations'

type AccountMenuProps = {
  sx?: UIStyles
}

const styles = {
  flexDirection: 'column',
  width: '183px',
  boxShadow: `0 4px 15px 0 ${colors.blacks[850]}`,
  borderRadius: '0 0 8px 8px',
  bg: 'white',
  zIndex: 'menu',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  top: '75px',
  py: 3,
  px: 4,
  transition: 'all 250ms',
  animation: `${fadeIn} 250ms ease-in-out`,
  svg: {
    mr: 4,
    mt: '2px'
  },
  button: {
    cursor: 'pointer',
    borderRadius: 0,
    textDecoration: 'none',
    fontSize: [1, 2],
    width: '100%'
  },
  menuItem: {
    textAlign: 'left',
    color: 'grays.600',
    my: '2px',
    py: 3,
    pl: 4,
    lineHeight: '18px',
    display: 'inline-flex',
    alignItems: 'center',
    '&:hover': {
      color: 'grays.600',
      bg: 'grays.40'
    }
  }
}

const AccountMenu = ({ sx = {} }: AccountMenuProps) => {
  const { accountMenu } = useMenuItems()
  const [, { signOut }] = useAuthContext()

  return (
    <Box sx={{ ...styles, ...sx }} data-testid="account-menu">
      {accountMenu.map(({ to, label, target, icon: Icon }: AccountItem) => {
        return (
          <Link key={label} to={to} target={target} sx={styles.menuItem}>
            <Icon />
            <Box as="span">{label}</Box>
          </Link>
        )
      })}
      <Button
        variant="link"
        onClick={async () => await signOut()}
        sx={styles.menuItem}
        size="small"
      >
        <LogoutIcon />
        <Box as="span">Sign Out</Box>
      </Button>
    </Box>
  )
}

export default AccountMenu
