import analytics from '@lib/gtm/analytics'
import { Maybe } from 'src/generated/graphql'

type NavigationAnalytics = {
  trackRootNavMenu: (label: string) => void
  trackNavMenuitem: (label: string) => void
  trackThirdLevelMenu: (label: string) => void
  trackInlineMenu: (
    currentUrl: string,
    destinationUrl: Maybe<string> | undefined
  ) => void
}

const DEFAULT_CATEGORY = 'Navigation'

const track = (action: string, label: string): void => {
  analytics.track(DEFAULT_CATEGORY, action, {
    eventLabel: label
  })
}

const navigationAnalytics: NavigationAnalytics = {
  trackRootNavMenu: (label) => {
    track('Click Root Nav Menu', label)
  },
  trackNavMenuitem: (label) => {
    track('Clicked Subnav Menu Item', label)
  },
  trackThirdLevelMenu: (label) => {
    track('Clicked Third Level Menu Item', label)
  },
  trackInlineMenu: (currentUrl, destinationUrl = '/') => {
    analytics.track('Inline Menu Item', 'click', {
      eventLabel: 'Inline Menu Item redirect',
      currentUrl,
      destinationUrl
    })
  }
}

export const trackingNavigationAnalytics = (
  name: string
): Record<number, Function> => ({
  1: (): void => navigationAnalytics.trackRootNavMenu(name),
  2: (): void => navigationAnalytics.trackNavMenuitem(name),
  3: (): void => navigationAnalytics.trackThirdLevelMenu(name)
})

export default navigationAnalytics
