import HelloBar from '@molecules/HelloBar/HelloBar'
import NavBar from '@molecules/NavBar/NavBar'
import EmailBanner from '@molecules/EmailBanner/EmailBanner'
import { Box } from '@lib/UIComponents'

type Props = {
  useHelloBar?: boolean
  useSearchBox?: boolean
  useInlineMenu?: boolean
  useMiniCartButton?: boolean
}

const Header = ({
  useHelloBar,
  useSearchBox,
  useInlineMenu,
  useMiniCartButton
}: Props) => {
  return (
    <Box
      as="header"
      id="header"
      sx={{
        zIndex: 'header',
        position: 'sticky',
        top: 0,
        left: 0,
        right: 0
      }}
    >
      <EmailBanner />
      {useHelloBar && <HelloBar />}
      <NavBar
        useSearchBox={useSearchBox}
        useInlineMenu={useInlineMenu}
        useMiniCartButton={useMiniCartButton}
      />
    </Box>
  )
}

export default Header
