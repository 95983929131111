import { keyframes } from '@emotion/core'

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const fadeIn03 = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
`
