import { createContext, useContext } from 'react'
import { F } from 'ramda'

export type CartPopupContextType = {
  isOpen: boolean
  setIsOpen: Function
  displayItemAddedMessage: boolean
  handleMiniCartPopup: Function
  handleNavBarPosition: Function
  popUpMobileMaxHeight: string
  navbarHeight: string
}

export const CartPopupContext = createContext<CartPopupContextType>({
  isOpen: false,
  setIsOpen: F,
  displayItemAddedMessage: false,
  handleMiniCartPopup: F,
  handleNavBarPosition: F,
  popUpMobileMaxHeight: 'auto',
  navbarHeight: ''
})

export const useCartPopupContext = (): CartPopupContextType =>
  useContext(CartPopupContext)
