import dynamic from 'next/dynamic'
import { useEffect, useRef, useState } from 'react'

import Close from '@atoms/Close/Close'
import Logo from '@molecules/Logo/Logo'
import { useNavBarContext } from '@concepts/Publisher/store/context'
import MenuList from './MenuList'
import { adaptMenuChildrenToMenu } from './menuFormatter'
import { Box } from '@lib/UIComponents'

/* istanbul ignore next */
const Overlay = dynamic(() => import('@atoms/Overlay/Overlay'), { ssr: false })

type MenuProps = {
  hidden?: boolean
  closeHandler: Function
}

const transition = { transition: 'all 350ms' }

const sx = {
  nav: (helloBarHeight: number) => ({
    width: '100%',
    maxWidth: [320, 400, 400, 450],
    height: '100%',
    bg: 'white',
    zIndex: 'menu',
    position: 'fixed',
    left: 0,
    top: helloBarHeight,
    py: [4, 8],
    px: 0,
    ...transition
  }),
  logo: { textAlign: 'center', pb: 4 },
  menu: { position: 'relative', height: '100%' }
}

const animate = (condition: boolean): { box: object; nav: object } => ({
  box: { visibility: condition ? 'visible' : 'hidden' },
  nav: {
    transform: condition
      ? 'translateX(0)'
      : [
          'translateX(-320px)',
          'translateX(-400px)',
          'translateX(-400px)',
          'translateX(-450px)'
        ],
    overflow: 'hidden',
    color: 'navBarText',
    bg: 'headerBackground'
  }
})

const Menu = ({ closeHandler, hidden }: MenuProps) => {
  const { rootNav } = useNavBarContext()
  const rootNavId = rootNav?.id || ''
  const [expanded, expand] = useState(rootNavId)
  const [menuLevel, setMenuLevel] = useState(1)
  const [previousMenu, setPreviousMenu] = useState<Array<string>>([])
  const styles = animate(!hidden)
  const nav = adaptMenuChildrenToMenu(rootNav)
  const helloBarHeight = useRef(0)

  useEffect(() => {
    helloBarHeight.current =
      document.getElementById('helloBar')?.offsetHeight || 0
  }, [])

  const close = (): void => {
    closeHandler()

    // It is used to avoid animation before the menu be fully closed
    setTimeout(() => {
      expand(rootNavId)
      setMenuLevel(1)
      setPreviousMenu([])
    }, 300)
  }

  return (
    <Box sx={styles.box}>
      <Box
        as="nav"
        role="navigation"
        data-testid="navigation"
        sx={{ ...sx.nav(helloBarHeight.current), ...styles.nav }}
      >
        <Close
          onClick={close}
          aria-label="Close Menu"
          sx={{
            position: 'absolute',
            left: ['22px', 8],
            color: 'navBarText',
            fontSize: [2, 4]
          }}
        />
        <Box sx={sx.logo}>
          <Logo />
        </Box>
        <Box sx={sx.menu}>
          <MenuList
            menuLevel={menuLevel}
            setMenuLevel={setMenuLevel}
            expanded={expanded || rootNavId}
            expand={expand}
            items={nav}
            rootId={rootNavId}
            previousMenu={previousMenu}
            setPreviousMenu={setPreviousMenu}
          />
        </Box>
      </Box>

      {!hidden && <Overlay freezeScroll onClick={close} sx={transition} />}
    </Box>
  )
}

export default Menu
