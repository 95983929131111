import { usePublisherContext } from '@concepts/Publisher/store/context'
import Link, { LinkProps } from '@atoms/UILink/Link'
import { Image } from '@lib/UIComponents'

type LogoProps = Partial<LinkProps>

const Logo = ({ sx, ...props }: LogoProps) => {
  const { name, logoMain, logoMainMobile } = usePublisherContext()

  return (
    <Link to="/" external sx={{ display: 'inline-block', ...sx }} {...props}>
      {logoMainMobile?.url && (
        <Image
          src={logoMainMobile.url}
          alt={`${name} Logo mobile`}
          sx={{ height: '32px', display: ['block', 'none'] }}
        />
      )}

      {logoMain?.url && (
        <Image
          src={logoMain.url}
          alt={`${name} Logo`}
          sx={{ height: '40px', display: ['none', 'block'] }}
        />
      )}
    </Link>
  )
}

export default Logo
