import Button from '@atoms/UIButton/Button'
import { ShoppingCartOpenIcon, ShoppingCartRebrandIcon } from '@atoms/Icon/Icon'
import { Text } from '@lib/UIComponents'
import useMiniCart from '@concepts/Cart/hooks/useMiniCart'
import { useCartPopupContext } from '@concepts/Cart/store/popupContext'

const styles = {
  cartQuantity: (quantity: number): object => ({
    color: 'white',
    position: 'absolute',
    left: '50%',
    transform: `translate(-${quantity > 9 ? '30' : '22'}%, 0%)`,
    top: ['16px', '15px', '15px', '27px'],
    fontSize: ['11px', '12px']
  })
}

const CartButton = () => {
  const { quantity: cartQuantity } = useMiniCart()
  const {
    isOpen,
    setIsOpen: setPopupOpen,
    handleNavBarPosition
  } = useCartPopupContext()

  const toggleMiniCart = () => {
    if (!isOpen) {
      handleNavBarPosition()
    }

    setPopupOpen(!isOpen)
  }

  return (
    <Button
      size="small"
      aria-label="Shopping Cart"
      data-testid="cart-button"
      sx={{
        border: 0,
        minWidth: 'auto',
        color: 'navBarText',
        fontSize: ['24px', 5],
        ml: [0, 0, 2, 6],
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        px: [2, 2, 1, 2],
        backgroundColor: 'transparent',
        py: [6, 6, 6, '36px'],
        '&:hover': {
          svg: {
            color: 'signInHoverColor'
          },
          backgroundColor: 'transparent'
        }
      }}
      onClick={toggleMiniCart}
    >
      {cartQuantity > 0 ? (
        <>
          <ShoppingCartOpenIcon />
          <Text sx={styles.cartQuantity(cartQuantity)}>{cartQuantity}</Text>
        </>
      ) : (
        <ShoppingCartRebrandIcon />
      )}
    </Button>
  )
}

export default CartButton
