import Button from '@atoms/UIButton/Button'
import Link from '@atoms/UILink/Link'
import { supportLinks } from '@utils/supportLinks'

import useGuestToken from '@concepts/Auth/hooks/useGuestToken'
import { useAuthContext } from '@concepts/Auth/store/context'
import { setReturnToCookie } from '@concepts/Auth/utils/redirect'
import routes from '@config/routes'

import { Box } from '@lib/UIComponents'
import { BoxProps } from '@lib/UIComponents/Box/Box'

import noop from '@utils/noop'
import { getQueryString } from '@utils/url'

export type LinkType = {
  text: string
  target?: string
  url?: string
  onClick: () => void
}

const links: Array<LinkType> = [
  {
    text: 'Contact',
    url: supportLinks.contact(),
    onClick: noop
  },
  {
    text: 'Shipping',
    url: supportLinks.shipping(),
    onClick: noop
  },
  {
    text: 'FAQs',
    url: supportLinks.help(),
    onClick: noop
  },
  {
    text: 'Return and Refund Policy',
    url: supportLinks.refunds(),
    onClick: noop
  }
]

export const loginLink = (): LinkType => {
  const params = {
    source: 'lower+footer+login+link'
  }

  return {
    text: 'Login',
    url: `${routes.login()}${getQueryString(params)}`,
    onClick: () => setReturnToCookie()
  }
}

export const accountLink = (gtoken?: string): LinkType => {
  const params = {
    show: 'account-tab',
    source: 'lower+footer+account+link',
    gtoken
  }

  const url = `/user${getQueryString(params)}`

  return {
    text: 'Account',
    url,
    onClick: noop
  }
}

type Props = {
  color?: string
} & BoxProps

const SiteLinks = ({ color = 'text', ...props }: Props) => {
  const [{ isSignedIn }, { signOut }] = useAuthContext()

  const { gtoken } = useGuestToken()

  const showAccountLink = isSignedIn || gtoken

  const allLinks = [
    showAccountLink ? accountLink(gtoken) : loginLink(),
    ...links
  ]

  return (
    <>
      {allLinks.map((link, index) => {
        const url = link.url as string
        const handleOnClick = link.onClick

        return (
          <Box as="li" data-testid="site-links" key={index} {...props}>
            <Link
              to={url}
              target={link.target}
              external
              onClick={handleOnClick}
              sx={{ color }}
            >
              {link.text}
            </Link>
          </Box>
        )
      })}
      {showAccountLink && (
        <Box as="li" {...props}>
          <Button
            onClick={async () => await signOut()}
            sx={{
              all: 'unset',
              color: 'menuSecondaryColor',
              cursor: 'pointer'
            }}
          >
            Sign Out
          </Button>
        </Box>
      )}
    </>
  )
}

export default SiteLinks
