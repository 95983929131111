import Alert from '@atoms/Alert/Alert'
import Close from '@atoms/Close/Close'
import Link from '@atoms/UILink/Link'
import { usePublisherContext } from '@concepts/Publisher/store/context'
import segmentAnalytics from '@lib/segment/analytics'
import { Box } from '@lib/UIComponents'
import EmailRegister from '@molecules/EmailRegister/EmailRegister'
import EmailBannerModal from './EmailBannerModal'
import { useEmailBanner } from './hooks/useEmailBanner'

const EmailBanner = () => {
  const { isBannerOpen, showModal, toggleModal, closeBanner } = useEmailBanner()

  const publisher = usePublisherContext()

  if (!publisher.layout?.emailBanner) return <></>

  const { saleSlug, offerText, modalBody, modalTitle, modalButtonText } =
    publisher.layout.emailBanner

  const onSubmit = (error?: string) => {
    if (error) return

    segmentAnalytics.trackEmailBanner()

    toggleModal(true)
  }

  return (
    <>
      {isBannerOpen && (
        <Alert
          sx={{
            display: ['none', 'none', 'none', 'flex'],
            position: 'relative',
            justifyContent: 'space-between',
            transition: 'max-height 250ms',
            p: '8px 64px 8px 64px',
            bg: 'black',
            color: 'white'
          }}
        >
          <Box as="span" sx={{ fontSize: 1, fontWeight: 'bold' }}>
            {offerText}
          </Box>

          <Box>
            <Box
              sx={{
                form: {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                },
                input: {
                  borderRadius: 0,
                  border: 'none',
                  borderBottom: '2px solid',
                  borderColor: 'grays.700',
                  transition: 'border-color 250ms',
                  '&:focus': {
                    outline: 0,
                    borderColor: 'white'
                  }
                },

                button: {
                  fontSize: [1, 1],
                  mt: 2,
                  fontWeight: 'bold',
                  minWidth: '100px',
                  minHeight: '30px',
                  justifyContent: 'center'
                }
              }}
            >
              <EmailRegister
                btnText="Get Offer"
                analyticsName="Email Banner"
                executeOnSubmit={onSubmit}
                variant="dark"
                inputWidth="400px"
                source="Email Hello Bar"
              />
            </Box>
            <Box as="span" sx={{ fontSize: '11px', color: 'grays.150' }}>
              By joining our mailing list you agree to our{' '}
              <Link to="/terms" sx={{ color: 'grays.40' }}>
                Terms and Conditions
              </Link>
            </Box>
          </Box>

          <Close
            onClick={closeBanner}
            data-testid="close banner"
            sx={{
              position: 'absolute',
              color: 'white',
              top: '30%',
              right: '18px',
              transform: ['none', 'none', 'none', 'translateY(-50%)']
            }}
          />
        </Alert>
      )}

      <EmailBannerModal
        showModal={showModal}
        toggleModal={toggleModal}
        modalTitle={modalTitle}
        modalBody={modalBody}
        saleSlug={saleSlug}
        modalButtonText={modalButtonText}
      />
    </>
  )
}

export default EmailBanner
