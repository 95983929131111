import { useCheckoutContext } from '../store/context'
import { useCartContext } from '../../Cart/store/context'
import { useEffect } from 'react'
import { AGE_RESTRICTION } from '@config/env'
import { getCookie, setCookie } from '@utils/cookies'

export type useAgeRestrictionType = {
  ageRestriction: number
  isEnabled: boolean
  disabledByAge: boolean
  acceptAgeRestriction: boolean
  setAcceptAgeRestriction: Function
}

const useAgeRestriction = (): useAgeRestrictionType => {
  const [{ acceptAgeRestriction }, , { setAcceptAgeRestriction }] =
    useCheckoutContext()
  const { cart } = useCartContext()
  const disabledByAge = cart.ageRestriction ? !acceptAgeRestriction : false

  useEffect(() => {
    const savedAcceptedAgeRestriction = JSON.parse(
      (getCookie(AGE_RESTRICTION) as string) || 'false'
    )

    /* istanbul ignore else */
    if (savedAcceptedAgeRestriction) {
      setAcceptAgeRestriction(savedAcceptedAgeRestriction)
    }
  }, [setAcceptAgeRestriction])

  useEffect(() => {
    setCookie(AGE_RESTRICTION, JSON.stringify(acceptAgeRestriction))
  }, [acceptAgeRestriction])

  return {
    ageRestriction: cart.ageRestriction,
    isEnabled: Boolean(cart.ageRestriction),
    disabledByAge,
    acceptAgeRestriction,
    setAcceptAgeRestriction
  }
}

export default useAgeRestriction
