import Button, { Props, Size } from '@atoms/UIButton/Button'
import { CloseIcon } from '@atoms/Icon/Icon'

const styles = {
  borderRadius: 'circle',
  lineHeight: 0
}

const sizes = {
  small: {
    p: 0,
    fontSize: [1, 2]
  },
  medium: {
    p: 1,
    fontSize: [1, 2]
  },
  large: {
    p: 2,
    fontSize: 2
  }
}

const Close = ({ size = 'medium', sx, ...props }: Props) => (
  <Button
    variant="close"
    aria-label="Close"
    type="button"
    sx={{ ...styles, ...sx }}
    {...sizes[size as Size]}
    {...props}
  >
    <CloseIcon />
  </Button>
)

export default Close
