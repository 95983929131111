import Link, { Path, LinkProps } from '@atoms/UILink/Link'
import { Heading as UIHeading } from '@lib/UIComponents'
import { AsType, UIStyles } from '@lib/UIComponents/types'

type Level = 1 | 2 | 3 | 4 | 5 | 6

export type HeadingProps = {
  level?: Level
  link?: LinkProps
  children: React.ReactNode
  sx?: UIStyles
  as?: AsType
  id?: string
}

const levels = {
  1: { fontSize: 6 },
  2: { fontSize: 5 },
  3: { fontSize: 4 },
  4: { fontSize: 3 },
  5: { fontSize: 2 },
  6: { fontSize: 1 }
}

const Heading = ({
  as = 'h2',
  level = 2,
  children,
  link,
  ...props
}: HeadingProps) => {
  if (link?.to) {
    const { to, ...linkProps } = link

    children = (
      <Link to={to as Path} {...linkProps}>
        {children}
      </Link>
    )
  }

  return (
    <UIHeading as={as} {...levels[level]} {...props}>
      {children}
    </UIHeading>
  )
}

export default Heading
