import Link from '@atoms/UILink/Link'
import { Text } from '@lib/UIComponents'
import Alert from '@molecules/Alert/Alert'
import { useEmailBanner } from '@molecules/EmailBanner/hooks/useEmailBanner'
import { useHelloBar } from './hooks/useHelloBar'

const HelloBar = () => {
  const { active, path, text, applyAnalytics } = useHelloBar()
  const display = active && text?.length
  const { isBannerOpen } = useEmailBanner()

  const message = (
    <Text sx={{ fontWeight: ['normal', 'bold'], color: 'navHelloBarText' }}>
      {text}
    </Text>
  )

  return display ? (
    <Alert
      color="navHelloBarText"
      sx={{
        bg: 'navHelloBarBg',
        zIndex: 'topBar',
        position: 'relative'
      }}
      display={isBannerOpen ? ['block', 'block', 'block', 'none'] : 'block'}
    >
      {path?.length ? (
        <Link
          to={path}
          onClick={applyAnalytics}
          external
          sx={{
            color: 'navHelloBarText',
            '&:hover': { textDecoration: 'none' }
          }}
        >
          {message}
        </Link>
      ) : (
        message
      )}
    </Alert>
  ) : null
}

export default HelloBar
