import { Flex, Box } from 'rebass'

import Button from '@atoms/UIButton/Button'
import Close from '@atoms/Close/Close'
import Heading from '@atoms/UIHeading/Heading'
import Link from '@atoms/UILink/Link'
import Modal from '@atoms/Modal/Modal'
import segmentAnalytics from '@lib/segment/analytics'

type EmailBannerModalProps = {
  showModal: boolean
  toggleModal: (isOpen: boolean) => void
  modalTitle: string
  modalBody: string
  saleSlug: string
  modalButtonText: string
}

const EmailBannerModal = ({
  showModal,
  toggleModal,
  modalTitle,
  modalBody,
  saleSlug,
  modalButtonText
}: EmailBannerModalProps) => {
  return (
    <Modal
      showModal={showModal}
      toggleModal={toggleModal}
      data-testid="modal-banner"
      sx={{
        backgroundColor: 'black',
        color: 'grays.40',
        top: '30%',
        textAlign: 'center'
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          gap: 5,
          alignItems: 'center'
        }}
      >
        <Close
          onClick={() => toggleModal(false)}
          data-testid="close modal"
          sx={{
            position: 'absolute',
            color: 'white',
            top: 3,
            right: 3
          }}
        />
        <Heading level={1} sx={{ fontSize: 8 }}>
          {modalTitle}
        </Heading>
        <Box sx={{ fontSize: 4, maxWidth: '350px' }}>{modalBody}</Box>
        <Button
          as={Link}
          to={`/sales/${saleSlug}`}
          onClick={() => {
            segmentAnalytics.trackEmailBannerModal({ saleSlug })
          }}
          sx={{
            color: 'black',
            backgroundColor: 'white',
            minHeight: '50px',
            minWidth: '300px',
            justifyContent: 'center',
            '&:hover': {
              textDecoration: 'none',
              backgroundColor: 'grays.50'
            }
          }}
        >
          {modalButtonText}
        </Button>
      </Flex>
    </Modal>
  )
}

export default EmailBannerModal
