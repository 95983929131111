import { Flex } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type AlertProps = {
  sx?: UIStyles
  variant?: 'success' | 'error'
  rounded?: boolean
  children?: React.ReactNode
}

const Alert = ({ sx, variant, rounded, children }: AlertProps) => {
  const variants = {
    success: {
      bg: 'greens.0',
      color: 'greens.600'
    },
    error: {
      bg: 'reds.0',
      color: 'reds.600'
    }
  }

  return (
    <Flex
      sx={{
        p: 4,
        bg: 'grays.50',
        color: 'grays.750',
        alignItems: 'center',
        ...(variant && variants[variant]),
        ...(rounded && { borderRadius: 'default' }),
        ...sx
      }}
    >
      {children}
    </Flex>
  )
}

export default Alert
