import Button from '@atoms/UIButton/Button'
import Container from '@atoms/UIContainer/Container'
import Link from '@atoms/UILink/Link'
import { Box } from '@lib/UIComponents'
import navigationAnalytics from '@lib/gtm/events/navigation'

import { normalizeMenuUrl } from '@utils/url'
import { NavItem } from 'src/generated/graphql'
import { MenuDropdown } from './MenuDropdown'

const InlineMenuItem = ({ navItem }: { navItem: NavItem }) => {
  return (
    <Container
      as="ul"
      sx={{
        ':hover > li > ul': {
          display: ['none', 'block'],
          visibility: ['hidden', 'visible']
        }
      }}
      data-testid="menu item"
      aria-label="menu item"
      aria-haspopup="true"
    >
      <Box as="li">
        <Button
          key={navItem.id}
          as={Link}
          to={normalizeMenuUrl(navItem.url || '/')}
          variant="default"
          size="small"
          sx={{
            paddingX: '1rem',
            justifyContent: 'center',
            mx: [2, 4],
            pt: [2, 6],
            pb: [3, 6],
            whiteSpace: 'nowrap',
            border: ['1px solid', 'none'],
            bg: ['navButtonBackgroundMobileColor', 'transparent'],
            '&, &:hover, &:active': {
              color: ['navButtonTextMobileColor', 'navButtonTextDesktopColor']
            },
            '&:hover, &:active': {
              textDecoration: ['none', 'underline'],
              textDecorationThickness: ['0', '1.5px'],
              bg: ['primaryHover', 'transparent']
            }
          }}
          onClick={() => {
            navigationAnalytics.trackInlineMenu(
              window.location.href,
              navItem.url
            )
          }}
        >
          {navItem.name}
        </Button>
      </Box>
      <Box as="li">
        <MenuDropdown navigationChildren={navItem.children} />
      </Box>
    </Container>
  )
}

export { InlineMenuItem }
