import { useEffect } from 'react'

type UseModalProps = {
  toggleModal: (visibility: boolean) => void
}

const useModal = ({ toggleModal }: UseModalProps) => {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      const key = e.key
      if (key === 'Esc' || key === 'Escape') toggleModal(false)
    }

    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [toggleModal])

  const closeModal = () => {
    toggleModal(false)
  }

  return { closeModal }
}

export { useModal }
