import Link from '@atoms/UILink/Link'
import { ChevronRightIcon } from '@atoms/Icon/Icon'
import { normalizeMenuUrl } from '@utils/url'
import { UIStyles } from '@lib/UIComponents/types'
import { Flex, Text } from '@lib/UIComponents'

type Size = 'medium' | 'large'

type MenuItemTitleProps = {
  title: string
  url: string
  size?: Size
  arrow?: boolean
  onClick?: () => void
  sx?: UIStyles
}

const sizes = {
  medium: { fontSize: [3, 4] },
  large: { fontSize: [4, 6] }
}

const styles = {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  py: [3, 4],
  lineHeight: 'heading',
  fontWeight: 'heading',
  '&:hover': {
    color: 'primary'
  }
}

const MenuItemTitle = ({
  title,
  url,
  size = 'medium',
  arrow = false,
  sx,
  onClick,
  ...props
}: MenuItemTitleProps) => {
  const hasURL = url?.length > 0

  const colors = {
    color: 'navBarText',
    '&:hover': {
      color: 'primary'
    }
  }

  const icon = arrow ? (
    <Flex
      as="span"
      sx={{
        justifyContent: 'flex-end',
        ml: 'auto',
        mt: '-8px',
        fontSize: '.7em'
      }}
    >
      <ChevronRightIcon />
    </Flex>
  ) : null

  const text = (
    <Text
      casing="uppercase"
      {...sizes[size as Size]}
      onClick={onClick}
      sx={{
        ...styles,
        ...sx,
        ...colors
      }}
      {...(arrow && { role: 'button', 'aria-label': `Open ${title}` })}
      {...props}
    >
      {title}
      {icon}
    </Text>
  )

  return hasURL && !arrow ? (
    <Link
      to={normalizeMenuUrl(url)}
      prefetch={false}
      sx={{
        display: 'flex',
        ...colors
      }}
    >
      {text}
    </Link>
  ) : (
    text
  )
}

export default MenuItemTitle
