import { forwardRef } from 'react'

import Loader from '@atoms/Loader/Loader'
import { Button as UIButton } from '@lib/UIComponents'
import { ButtonProps } from '@lib/UIComponents/types'

export type Size = 'small' | 'medium' | 'large'

export type Props = {
  block?: boolean
  loading?: boolean
  size?: Size
  disabled?: boolean
  target?: string
  to?: string
} & ButtonProps

const sizes = (size: Size): object => {
  return {
    small: {
      minHeight: '30px',
      px: 3,
      fontSize: 1
    },
    medium: {
      minHeight: '40px',
      px: 4,
      fontSize: 2
    },
    large: {
      minHeight: '45px',
      px: 8,
      fontSize: 2
    }
  }[size]
}

const Button = forwardRef(
  (
    {
      block = false,
      loading = false,
      disabled = false,
      size = 'medium',
      variant = 'primary',
      children,
      sx,
      ...props
    }: Props,
    ref
  ) => (
    <UIButton
      ref={ref}
      variant={variant}
      isDisabled={disabled || loading}
      sx={{
        ...sizes(size),
        ...(block && {
          display: 'block',
          width: '100%'
        }),
        ...sx
      }}
      {...props}
    >
      {loading ? <Loader /> : children}
    </UIButton>
  )
)

export default Button
